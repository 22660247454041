import type {SdkErrorCode} from '~/types/authorize';

const SILENT_SDK_ERROR_CODES: SdkErrorCode[] = [
  'captcha_challenge',
  'retriable_server_error',
];

const SILENT_MESSAGE_REGEX: RegExp[] = [
  /existing customer \d+ on shop \d+ has a conflicting provider subject associated: existing '([^']+)' != incoming '([^']+)'/,
];

export function isUsefulError(code: SdkErrorCode, message: string) {
  return !(
    SILENT_SDK_ERROR_CODES.includes(code) ||
    SILENT_MESSAGE_REGEX.some((regex) => regex.test(message))
  );
}
